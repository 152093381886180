import React from 'react'
import Menu from './Menu'
import { system } from '../preinit/system_capabilities'
import { getStreet, getStreetUrl, setStreet } from '../streets/data_model'
import { API_URL } from '../app/config'
import { saveStreetToServerIfNecessary } from '../streets/data_model'
import { newNonblockingAjaxRequest } from '../util/fetch_nonblocking'
import { isSignedIn } from '../users/authentication'
import { getIgnoreStreetChanges, setIgnoreStreetChanges } from '../streets/undo_stack'

export default class SubmitMenu extends React.Component {
    constructor (props) {
        super(props)
        this.onClickSubmit = this.onClickSubmit.bind(this)
        this.changeProfession = this.changeProfession.bind(this)
    }

    changeProfession () {
        const profession = document.querySelector('#submit-form-profession').value;
        if (profession === 'Other') {
          document.querySelector('#profession-other-wrap').style.display = 'block';
        } else {
          document.querySelector('#profession-other-wrap').style.display = 'none';
        }
    }

    onClickSubmit() {
        document.querySelector('#submit-form .loading').classList.add('visible')
        document.querySelector('#submit-form .msg').classList.remove('visible');

        var additionalInformation = '\nUser agent: ' + navigator.userAgent
        if (system.ipAddress) {
            additionalInformation += '\nIP: ' + system.ipAddress
        }
        var street = getStreet()

        var submission = {
            submittedOn: new Date(),
            email: document.querySelector('#submit-form-email').value,
            followup: document.querySelector('#submit-form-followup').checked,
            age: document.querySelector('#submit-form-age').value,
            zip: document.querySelector('#submit-form-zip').value,
            cross1: document.querySelector('#submit-form-cross1').value,
            cross2: document.querySelector('#submit-form-cross2').value,
            profession: document.querySelector('#submit-form-profession').value,
            professionOther: document.querySelector('#submit-form-profession-other').value,
            anythingElse: document.querySelector('#submit-form-anything').value,
            additionalInformation: additionalInformation
        }

        street.submission = submission
        setStreet(street)
        let ignore = getIgnoreStreetChanges()
        setIgnoreStreetChanges(false)
        saveStreetToServerIfNecessary()
        setIgnoreStreetChanges(ignore)

        var data = Object.assign({}, submission);
        data.url = getStreetUrl(street)

        newNonblockingAjaxRequest({
            url: API_URL + 'v1/submit',
            data: JSON.stringify(data),
            dataType: 'json',
            type: 'POST',
            contentType: 'application/json'
        }, true, this.submitFormSuccess, this.submitFormError, 1)

        // ---------------------------------------------- ^^^
        // We are automatically halting the submit form sending if it fails 3 times.
        // If we do not do this, then Streetmix will continue to re-attempt sending submit
        // forever, while claiming that Streetmix is having trouble connecting to the Internet.
        // It is more likely that Sendgrid is having problems than the Internet connection itself
        // (e.g. the Sendgrid connection is bad, the credentials are bad, etc.)
        // So if Sendgrid is having problems, instead just inform the user that the
        // submit could not be sent, and move on.
    }

    submitFormSuccess () {
        document.querySelector('#submit-form .loading').classList.remove('visible')
        document.querySelector('#submit-form .thank-you').classList.add('visible')
        document.querySelector('#submit-form .msg').classList.remove('visible');
    }

    submitFormError (xhr) {
        if (xhr.responseJSON && xhr.responseJSON.msg) {
            document.querySelector('#submit-form .loading').classList.remove('visible')
            document.querySelector('#submit-form .msg').classList.add('visible');
            document.querySelector('#submit-form .msg').innerHTML = xhr.responseJSON.msg;
        } else {
            document.querySelector('#submit-form .loading').classList.remove('visible')
            document.querySelector('#submit-form .error').classList.add('visible')
        }

        // On error, do not clear the submit storage. This allows a user
        // to try again later, or copy-paste the message to some other
        // contact method.
    }

    render () {
        return (
            <Menu name='submit' alignment='right' onShow={this.onShow} className='submit-menu' {...this.props}>
                <div id="submit-form" className="form">
                    <div className="submit-notice loading">Loading…</div>
                    <div className="submit-notice thank-you">We got it. Thanks!</div>
                    <div className="submit-notice msg"></div>
                    <div className="submit-notice error">We’re sorry, there was a problem sending feedback at this time. Please try again later.</div>
                    <label htmlFor="submit-form-email">
                        Enter your email address to get a copy.
                        <input id="submit-form-email" type="email" placeholder="Your email (optional)" spellCheck="false" />
                    </label>
                    <p>
                        <label htmlFor="submit-form-followup">
                            <input id="submit-form-followup" type="checkbox" />
                            Check if you'd be willing for us to follow up with you.
                        </label>
                    </p>
                    <p>
                        <label htmlFor="submit-form-age">
                            Your Age:
                            <input id="submit-form-age" type="text" placeholder="Age" />
                        </label>
                    </p>
                    <p>
                        <label htmlFor="submit-form-zip">
                            Your Zipcode:
                            <input id="submit-form-zip" type="text" placeholder="Zipcode" />
                        </label>
                    </p>

                    <p>
                        <label htmlFor="submit-form-zip">
                            Your Profession: &nbsp;
                            <select id="submit-form-profession" onChange={this.changeProfession}>
                                <option value="">Select one</option>
                                <option value="Academic">Academic</option>
                                <option value="Urban Planning Consultant">Urban Planning Consultant</option>
                                <option value="Government">Government</option>
                                <option value="Other">Other</option>
                            </select>
                        </label>
                        <br/>
                        <span id="profession-other-wrap" style={{display: 'none'}}>
                            <label htmlFor="submit-form-profession-other">Other:</label>
                            <input id="submit-form-profession-other" type="text" placeholder="" />
                        </span>
                    </p>

                    <p>
                        <label htmlFor="submit-form-cross1">
                            Your Nearest Cross Street:
                            <br />
                            <input id="submit-form-cross1" type="text" placeholder="Cross Street 1" />
                            &nbsp;
                            and
                            &nbsp;
                            <input id="submit-form-cross2" type="text" placeholder="Cross Street 2" />
                        </label>
                    </p>

                    <p>
                        <label htmlFor="submit-form-anything">Anything else?</label>
                        <br/>
                        <textarea id="submit-form-anything" cols="46" rows="3"></textarea>
                    </p>

                    <p>
                        <button id="submit-form-send" onClick={this.onClickSubmit}>Submit</button>
                    </p>
                </div>
            </Menu>
        )
    }
}
