import React from 'react'

export default class NoMatch extends React.Component {
    componentWillMount() {

        /*
    } else if ((urlParts.length === 1) && (urlParts[0] === URL_NEW_STREET)) {
    // New street

    setMode(MODES.NEW_STREET)
} else if ((urlParts.length === 1) && (urlParts[0] === URL_NEW_STREET_COPY_LAST)) {
    // New street (but start with copying last street)

    setMode(MODES.NEW_STREET_COPY_LAST)
} else if ((urlParts.length === 1) && (urlParts[0] === URL_JUST_SIGNED_IN)) {
    // Coming back from a successful sign in

    setMode(MODES.JUST_SIGNED_IN)
} else if ((urlParts.length >= 1) && (urlParts[0] === URL_ERROR)) {
    // Error

    setMode(MODES.ERROR)
    errorUrl = urlParts[1]
} else if ((urlParts.length === 1) && (urlParts[0] === URL_GLOBAL_GALLERY)) {
    // Global gallery

    setMode(MODES.GLOBAL_GALLERY)
} else if ((urlParts.length === 1) && urlParts[0]) {
    // User gallery

    galleryUserId = urlParts[0]

    setMode(MODES.USER_GALLERY)
} else if ((urlParts.length === 2) && (urlParts[0] === URL_NO_USER) && urlParts[1]) {
    // TODO add is integer urlParts[1]
    // Existing street by an anonymous person

    street.creatorId = null
    street.namespacedId = urlParts[1]

    setMode(MODES.EXISTING_STREET)
} else if ((urlParts.length >= 2) && urlParts[0] && urlParts[1]) {
    // TODO add is integer urlParts[1]
    // Existing street by a signed in person

    street.creatorId = urlParts[0]

    if (street.creatorId.charAt(0) === URL_RESERVED_PREFIX) {
        street.creatorId = street.creatorId.substr(1)
    }

    street.namespacedId = urlParts[1]

    setMode(MODES.EXISTING_STREET)
} else {
    setMode(MODES.NOT_FOUND)
}
*/
    }
    render () {
        return (
            <div className='no-match'>
            </div>
        )
    }
}

