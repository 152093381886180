'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _KeyCode = require('rc-util/lib/KeyCode');

var _KeyCode2 = _interopRequireDefault(_KeyCode);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _util = require('./util');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/* eslint react/no-is-mounted:0 */

var MenuItem = _react2["default"].createClass({
  displayName: 'MenuItem',

  propTypes: {
    rootPrefixCls: _react.PropTypes.string,
    eventKey: _react.PropTypes.string,
    active: _react.PropTypes.bool,
    children: _react.PropTypes.any,
    selectedKeys: _react.PropTypes.array,
    disabled: _react.PropTypes.bool,
    title: _react.PropTypes.string,
    onSelect: _react.PropTypes.func,
    onClick: _react.PropTypes.func,
    onDeselect: _react.PropTypes.func,
    parentMenu: _react.PropTypes.object,
    onItemHover: _react.PropTypes.func,
    onDestroy: _react.PropTypes.func,
    onMouseEnter: _react.PropTypes.func,
    onMouseLeave: _react.PropTypes.func
  },

  getDefaultProps: function getDefaultProps() {
    return {
      onSelect: _util.noop,
      onMouseEnter: _util.noop,
      onMouseLeave: _util.noop
    };
  },
  componentWillUnmount: function componentWillUnmount() {
    var props = this.props;
    if (props.onDestroy) {
      props.onDestroy(props.eventKey);
    }
    if (props.parentMenu.menuItemInstance === this) {
      this.clearMenuItemMouseLeaveTimer();
    }
  },
  onKeyDown: function onKeyDown(e) {
    var keyCode = e.keyCode;
    if (keyCode === _KeyCode2["default"].ENTER) {
      this.onClick(e);
      return true;
    }
  },
  onMouseLeave: function onMouseLeave(e) {
    var _this = this;

    var props = this.props;
    var eventKey = props.eventKey,
        parentMenu = props.parentMenu;

    parentMenu.menuItemInstance = this;
    parentMenu.menuItemMouseLeaveFn = function () {
      if (_this.isMounted() && props.active) {
        props.onItemHover({
          key: eventKey,
          item: _this,
          hover: false,
          domEvent: e,
          trigger: 'mouseleave'
        });
      }
    };
    parentMenu.menuItemMouseLeaveTimer = setTimeout(parentMenu.menuItemMouseLeaveFn, 30);
    props.onMouseLeave({
      key: eventKey,
      domEvent: e
    });
  },
  onMouseEnter: function onMouseEnter(e) {
    var props = this.props;
    var eventKey = props.eventKey,
        parentMenu = props.parentMenu;

    this.clearMenuItemMouseLeaveTimer(parentMenu.menuItemInstance !== this);
    if (parentMenu.subMenuInstance) {
      parentMenu.subMenuInstance.clearSubMenuTimers();
    }
    props.onItemHover({
      key: eventKey,
      item: this,
      hover: true,
      domEvent: e,
      trigger: 'mouseenter'
    });
    props.onMouseEnter({
      key: eventKey,
      domEvent: e
    });
  },
  onClick: function onClick(e) {
    var props = this.props;
    var selected = this.isSelected();
    var eventKey = props.eventKey;
    var info = {
      key: eventKey,
      keyPath: [eventKey],
      item: this,
      domEvent: e
    };
    props.onClick(info);
    if (props.multiple) {
      if (selected) {
        props.onDeselect(info);
      } else {
        props.onSelect(info);
      }
    } else if (!selected) {
      props.onSelect(info);
    }
  },
  getPrefixCls: function getPrefixCls() {
    return this.props.rootPrefixCls + '-item';
  },
  getActiveClassName: function getActiveClassName() {
    return this.getPrefixCls() + '-active';
  },
  getSelectedClassName: function getSelectedClassName() {
    return this.getPrefixCls() + '-selected';
  },
  getDisabledClassName: function getDisabledClassName() {
    return this.getPrefixCls() + '-disabled';
  },
  clearMenuItemMouseLeaveTimer: function clearMenuItemMouseLeaveTimer() {
    var props = this.props;
    var callFn = void 0;
    var parentMenu = props.parentMenu;
    if (parentMenu.menuItemMouseLeaveTimer) {
      clearTimeout(parentMenu.menuItemMouseLeaveTimer);
      parentMenu.menuItemMouseLeaveTimer = null;
      if (callFn && parentMenu.menuItemMouseLeaveFn) {
        parentMenu.menuItemMouseLeaveFn();
      }
      parentMenu.menuItemMouseLeaveFn = null;
    }
  },
  isSelected: function isSelected() {
    return this.props.selectedKeys.indexOf(this.props.eventKey) !== -1;
  },
  render: function render() {
    var props = this.props;
    var selected = this.isSelected();
    var classes = {};
    classes[this.getActiveClassName()] = !props.disabled && props.active;
    classes[this.getSelectedClassName()] = selected;
    classes[this.getDisabledClassName()] = props.disabled;
    classes[this.getPrefixCls()] = true;
    classes[props.className] = !!props.className;
    var attrs = (0, _extends3["default"])({}, props.attribute, {
      title: props.title,
      className: (0, _classnames2["default"])(classes),
      role: 'menuitem',
      'aria-selected': selected,
      'aria-disabled': props.disabled
    });
    var mouseEvent = {};
    if (!props.disabled) {
      mouseEvent = {
        onClick: this.onClick,
        onMouseLeave: this.onMouseLeave,
        onMouseEnter: this.onMouseEnter
      };
    }
    var style = (0, _extends3["default"])({}, props.style);
    if (props.mode === 'inline') {
      style.paddingLeft = props.inlineIndent * props.level;
    }
    return _react2["default"].createElement(
      'li',
      (0, _extends3["default"])({
        style: style
      }, attrs, mouseEvent),
      props.children
    );
  }
});

MenuItem.isMenuItem = 1;

exports["default"] = MenuItem;
module.exports = exports['default'];