import React from 'react'
import Menu from './Menu'
import { onSignOutClick } from '../users/authentication'
import { getStreet, setStreet, saveStreetToServerIfNecessary } from '../streets/data_model';

export default class IdentityMenu extends React.Component {

    constructor (props) {
        super(props)
        this.toggleStartingStreet.bind(this);

        this.state = {
            is_starting_street: false
        };

        window.addEventListener('stmx:set_street', this.handleStreetUpdated.bind(this));
        window.addEventListener('stmx:everything_loaded', this.handleStreetUpdated.bind(this));
    }
    handleStreetUpdated () {
        var street = getStreet();
        if (street) {
            this.setState({
                is_starting_street : street.is_starting_street
            });
        }
    }
    toggleStartingStreet (e) {
        e.preventDefault();

        var street = getStreet()
        street.is_starting_street = ! street.is_starting_street;
        setStreet(street)
        saveStreetToServerIfNecessary()
    }

    render () {
        var linkText = this.state.is_starting_street ? "Make Street not a Starting Street" : "Make Street a Starting Street";
        return (
            <Menu name='identity' {...this.props}>
                <a href='#' onClick={this.toggleStartingStreet}>
                    {linkText}
                </a>
                <a href='#' data-i18n='menu.item.sign-out' onClick={onSignOutClick}>
                    Sign out
                </a>
            </Menu>
        )
    }
}
