'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _cssAnimation = require('css-animation');

var _cssAnimation2 = _interopRequireDefault(_cssAnimation);

var _util = require('./util');

var _util2 = _interopRequireDefault(_util);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var transitionMap = {
  enter: 'transitionEnter',
  appear: 'transitionAppear',
  leave: 'transitionLeave'
};

var AnimateChild = _react2["default"].createClass({
  displayName: 'AnimateChild',

  propTypes: {
    children: _react2["default"].PropTypes.any
  },

  componentWillUnmount: function componentWillUnmount() {
    this.stop();
  },
  componentWillEnter: function componentWillEnter(done) {
    if (_util2["default"].isEnterSupported(this.props)) {
      this.transition('enter', done);
    } else {
      done();
    }
  },
  componentWillAppear: function componentWillAppear(done) {
    if (_util2["default"].isAppearSupported(this.props)) {
      this.transition('appear', done);
    } else {
      done();
    }
  },
  componentWillLeave: function componentWillLeave(done) {
    if (_util2["default"].isLeaveSupported(this.props)) {
      this.transition('leave', done);
    } else {
      // always sync, do not interupt with react component life cycle
      // update hidden -> animate hidden ->
      // didUpdate -> animate leave -> unmount (if animate is none)
      done();
    }
  },
  transition: function transition(animationType, finishCallback) {
    var _this = this;

    var node = _reactDom2["default"].findDOMNode(this);
    var props = this.props;
    var transitionName = props.transitionName;
    var nameIsObj = (typeof transitionName === 'undefined' ? 'undefined' : _typeof(transitionName)) === 'object';
    this.stop();
    var end = function end() {
      _this.stopper = null;
      finishCallback();
    };
    if ((_cssAnimation.isCssAnimationSupported || !props.animation[animationType]) && transitionName && props[transitionMap[animationType]]) {
      var name = nameIsObj ? transitionName[animationType] : transitionName + '-' + animationType;
      var activeName = name + '-active';
      if (nameIsObj && transitionName[animationType + 'Active']) {
        activeName = transitionName[animationType + 'Active'];
      }
      this.stopper = (0, _cssAnimation2["default"])(node, {
        name: name,
        active: activeName
      }, end);
    } else {
      this.stopper = props.animation[animationType](node, end);
    }
  },
  stop: function stop() {
    var stopper = this.stopper;
    if (stopper) {
      this.stopper = null;
      stopper.stop();
    }
  },
  render: function render() {
    return this.props.children;
  }
});

exports["default"] = AnimateChild;
module.exports = exports['default'];