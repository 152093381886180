'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var MenuItemGroup = _react2["default"].createClass({
  displayName: 'MenuItemGroup',

  propTypes: {
    renderMenuItem: _react.PropTypes.func,
    index: _react.PropTypes.number,
    className: _react.PropTypes.string,
    rootPrefixCls: _react.PropTypes.string
  },

  getDefaultProps: function getDefaultProps() {
    return {
      disabled: true
    };
  },
  renderInnerMenuItem: function renderInnerMenuItem(item, subIndex) {
    var _props = this.props,
        renderMenuItem = _props.renderMenuItem,
        index = _props.index;

    return renderMenuItem(item, index, subIndex);
  },
  render: function render() {
    var props = this.props;
    var _props$className = props.className,
        className = _props$className === undefined ? '' : _props$className,
        rootPrefixCls = props.rootPrefixCls;

    var titleClassName = rootPrefixCls + '-item-group-title';
    var listClassName = rootPrefixCls + '-item-group-list';
    return _react2["default"].createElement(
      'li',
      { className: className + ' ' + rootPrefixCls + '-item-group' },
      _react2["default"].createElement(
        'div',
        { className: titleClassName },
        props.title
      ),
      _react2["default"].createElement(
        'ul',
        { className: listClassName },
        _react2["default"].Children.map(props.children, this.renderInnerMenuItem)
      )
    );
  }
});

MenuItemGroup.isMenuItemGroup = true;

exports["default"] = MenuItemGroup;
module.exports = exports['default'];