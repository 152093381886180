import React from 'react'
import { hasInitialized, initialize} from '../app/initialization'

export default class Street extends React.Component {

    componentDidMount() {
        if ( ! hasInitialized()) {
            initialize()
        }
    }

    render () {
        return (
            <div className='main-wrap'>
                <div id="menus"></div>
                <div className="info-bubble"></div>
                <div id="status-message" className="status-message">
                    <div className="status-message-content"></div>
                </div>
                <div id="no-connection-message" className="status-message">
                    <div className="status-message-content">Streetmix is having trouble connecting to the Internet.
                        <button id="no-connection-try-again">Try again
                        </button>
                    </div>
                </div>
                <div id="street-name-canvas">
                    <div id="street-name" className="street-name"></div>
                    <div id="street-metadata">
                        <span id="street-metadata-width">
                            <span id="street-width-read" title="Change width of the street">
                                <span id="street-width-read-width"/>
                                &nbsp;
                                <span id="street-width-read-difference"/>
                            </span>
                            <select id="street-width" />
                        </span>
                        <span id="street-metadata-author" />
                        <span id="street-metadata-date" />
                    </div>
                </div>
                <div id="dialogs-react"></div>
                <div id="welcome">
                    <div>
                        <button className="close">×</button>
                        <div className="first-time-new-street">
                            <h1 data-i18n="dialogs.welcome.heading">Welcome to Streetmix.</h1>
                            <p>Design, remix, and share your neighborhood street. Add trees or bike paths, widen sidewalks or traffic lanes, learn how your decisions can impact your community.</p>
                            <p className="important">Start by moving some segments around with
                                <span className='touch-only'>your finger.</span>
                                <span className='non-touch-only'>your mouse.</span>
                            </p>
                        </div>
                        <div className="first-time-existing-street">
                            <h1 data-i18n="dialogs.welcome.heading">Welcome to Streetmix.</h1>
                            <p>This is
                                <span id="welcome-street-name" className="street-name" />
                                <span id='welcome-avatar-creator'>
                                    made by
                                    <span className='avatar' id='welcome-avatar' data-user-id='' />
                                    <span id='welcome-creator'/>
                                </span>.
                            </p>
                            <p className="important">Remix it by moving some segments around, or
                                <button id='welcome-new-street'>Start your own street</button>.
                            </p>
                        </div>
                        <div className="new-street">
                            <h1 data-i18n="dialogs.new-street.heading">Here’s your new street.</h1>
                            <ul>
                                <li>
                                    <input id="new-street-default" type="radio" name="new-street" />
                                    <label htmlFor="new-street-default" data-i18n="dialogs.new-street.default">Start with an example street</label>
                                </li>
                                <li>
                                    <input id="new-street-empty" type="radio" name="new-street" />
                                    <label htmlFor="new-street-empty" data-i18n="dialogs.new-street.empty">Start with an empty street</label>
                                </li>
                                <li>
                                    <input id="new-street-last" type="radio" name="new-street" />
                                    <label htmlFor="new-street-last" data-i18n="dialogs.new-street.last">Start with a copy of last street</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <section id="street-section-outer">
                    <div id="gallery-shield"></div>
                    <section id="street-section-inner">
                        <section id="street-section-canvas">
                            <section id="street-section-left-building" className="street-section-building">
                                <div className="hover-bk"></div>
                            </section>
                            <section id="street-section-right-building" className="street-section-building">
                                <div className="hover-bk"></div>
                            </section>
                            <div id="street-section-editable"></div>
                            <div id="street-section-left-empty-space" className="segment empty"></div>
                            <div id="street-section-right-empty-space" className="segment empty"></div>
                            <section id="street-section-dirt"/>
                        </section>
                    </section>
                </section>
                <section id="street-section-sky">
                    <div className="rear-clouds"></div>
                    <div className="front-clouds"></div>
                </section>
                <div id="street-scroll-indicator-left"></div>
                <div id="street-scroll-indicator-right"></div>
                <footer>
                    <div className="footer-canvas">
                        <div className="regular-canvas">
                            <div id="commands">
                                <button id="undo" data-i18n="btn.undo">Undo</button>
                                <button id="redo" data-i18n="btn.redo">Redo</button>
                            </div>
                            <div id="palette">
                                <div className="palette-canvas"></div>
                            </div>
                            <div id="trashcan" data-i18n="palette.remove">Drag here to remove</div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}



