import React from 'react'

/**
 * Generic street name sign creator.
 *
 */

// Output using cmap2file as per
// http://www.typophile.com/node/64147#comment-380776

const STREET_NAME_FONT_GLYPHS = ' !"#$%&\'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[]^_`abcdefghijklmnopqrstuvwxyz{|}~¡¢£¤¥¦§¨©ª«¬®¯°±²³´µ¶·¸¹º»¼½¾¿ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿĀāĂăĆćĈĉĊċČčĎďĒĔĕĖėĜĝĞğĠġĤĥĨĩĪīĬĭİıĴĵĹĺĽľŁłŃŇňŌōŎŏŐőŒœŔŕŘřŚśŜŝŞşŠšŤťŨũŪūŬŭŮůŰűŴŵŶŷŸŹźŻżŽžƒˆˇ˘˙˚˛˜˝–—‘’‚“”„†‡•…‰‹›⁄€™−'
const MAX_STREET_NAME_WIDTH = 50

export default class StreetName extends React.Component {

    /**
     * Some processing needed to display street name
     *
     * @public for main street name ¯\_(ツ)_/¯
     * @params {string} name - Street name to check
     */
    normalizeStreetName () {
        var name = this.props.name.trim()

        if (name.length > MAX_STREET_NAME_WIDTH) {
            name = name.substr(0, MAX_STREET_NAME_WIDTH) + '…'
        }

        return name
    }

    /**
     * Check if street name requires a Unicode font to render correctly
     *
     * @static
     * @params {string} name - Street name to check
     */
    needsUnicodeFont () {
        let needUnicodeFont = false

        for (let character of this.props.name) {
            if (STREET_NAME_FONT_GLYPHS.indexOf(character) === -1) {
                needUnicodeFont = true
                break
            }
        }

        return needUnicodeFont
    }

    render () {
        let classString = 'street-name-text '
        classString += this.needsUnicodeFont() ? 'fallback-unicode-font' : 'fallback-unicode-font'
        let name = this.normalizeStreetName()
        return (
            <div className="street-name">
                <span className={classString}>{name}</span>
            </div>
        )
    }
}

StreetName.propTypes = {
    name: React.PropTypes.string
}

