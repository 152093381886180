/**
 * Page (class)
 *
 * Generic class instance of a page
 *
 */
import React from 'react'

export default class App extends React.Component {
    render () {
        return (
            <div className='app-container'>
                {this.props.children}
            </div>
        )
    }
}

App.propTypes = {
    children: React.PropTypes.node
}
