/**
 * Page (class)
 *
 * Generic class instance of a page
 *
 */
import React from 'react'
import { t } from '../app/locale'
import DashboardMenu from '../menus/DashboardMenu'

export default class Page extends React.Component {
    constructor (props) {
        super(props)
    }

    componentDidMount () {

    }

    componentWillUnmount () {
    }

    render () {
        let className = 'page'
        if (this.props.className !== undefined) {
            className += ` ${this.props.className}`
        }

        return (
            <div className='page-container'>
                <div className={className}>
                    <DashboardMenu/>
                    <div className="page-content">
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}

Page.propTypes = {
    className: React.PropTypes.string,
    children: React.PropTypes.node
}
