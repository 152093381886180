import React from 'react'
import Page from './Page'
import $ from 'jquery'
import { API_URL } from '../app/config'
import { t } from '../app/locale'
import StreetThumbnail from '../components/StreetThumbnail'
import StreetName from '../components/StreetName'
import { onSignOutClick } from '../users/authentication'
import { unpackStreetDataFromServerTransmission } from '../streets/xhr'
import { getImagesToBeLoaded } from '../app/load_resources'
import { browserHistory } from 'react-router'
import { getAuthHeader } from '../users/authentication'

export default class Dashboard extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            starting_streets: []
        }

        this.updateSignInUI = this.updateSignInUI.bind(this)
        this.loadStreets = this.loadStreets.bind(this)
        window.addEventListener('stmx:signed_in', this.updateSignInUI)
        window.addEventListener('stmx:assets_loaded', this.loadStreets)
    }

    loadStreets () {
        var options = {
            url: API_URL + 'v1/dashboard/streets',
            dataType: 'json',
            headers: { 'Authorization': getAuthHeader() },
        }
        this.serverRequest = $.ajax(options).done(function (result) {
            var starting_streets = result.streets.map(unpackStreetDataFromServerTransmission);
            this.setState({
                starting_streets: starting_streets
            });
        }.bind(this));
    }

    componentDidMount() {
        if (getImagesToBeLoaded() == 0) {
            this.loadStreets()
        }
    }

    componentWillUnmount() {
        if (this.serverRequest) {
            this.serverRequest.abort();
        }
    }

    updateSignInUI (event) {
        // Sign-in details are passed in via `event.detail`. If a user is not
        // signed in, the event that calls this will pass an empty object for
        // `event.detail`
        if (event.detail.userId) {
            this.setState({ userId: event.detail.userId })
        }
    }

    render () {

        return (
            <Page className='dashboard'>
                <h1>{t('pages.home.heading', 'Admin Dashboard')}</h1>

                <ul className="starting-streets">
                    {this.state.starting_streets.map((street, index) =>
                        <li key={street.id}>
                            <a href='#' onClick={(e) => {e.preventDefault(); browserHistory.push('/admin/results/' + street.id)}}>
                                <StreetThumbnail
                                    street={street}
                                    silhouette={false}
                                    bottomAligned={false}
                                    transparentSky={true}
                                    segmentNamesAndWidths={false}
                                    streetName={false}
                                />
                                <StreetName
                                    name={street.name}
                                />
                            </a>
                          <p>
                              Total Submissions: {street.stats.totalSubmissions.length}
                          </p>
                        </li>
                    )}
                </ul>

            </Page>
        )
    }
}
