import React from 'react'
import Page from './Page'
import $ from 'jquery'
import { API_URL } from '../app/config'
import StreetThumbnail from '../components/StreetThumbnail'
import StreetName from '../components/StreetName'
import { onSignOutClick, getSignInData } from '../users/authentication'
import { unpackStreetDataFromServerTransmission } from '../streets/xhr'
import {
    getStreet,
    setStreet,
    setLastStreet,
    getStreetUrl,
    createDomFromData,
    createDataFromDom,
    saveStreetToServerIfNecessary
} from '../streets/data_model'
import { updateStreetName } from '../streets/name'
import { setServerContacted, checkIfEverythingIsLoaded } from '../app/initialization'
import { remixStreet } from '../streets/remix'
import { browserHistory } from 'react-router'
import { propagateUnits } from '../users/localization'
import { getImagesToBeLoaded } from '../app/load_resources'
import { getIgnoreStreetChanges, setIgnoreStreetChanges } from '../streets/undo_stack'

export default class HomePage extends React.Component {

    constructor (props) {
        super(props)

        const signin = getSignInData()

        this.state = {
            userId: signin ? signin.details.id : null,
            starting_streets: []
        }

        this.updateSignInUI = this.updateSignInUI.bind(this)
        this.loadStreets = this.loadStreets.bind(this)
        this.handleRemixStreet = this.handleRemixStreet.bind(this)
        this.handleStreetRemixed = this.handleStreetRemixed.bind(this)
        window.addEventListener('stmx:signed_in', this.updateSignInUI)
        window.addEventListener('stmx:assets_loaded', this.loadStreets)
        window.addEventListener('stmx:street_remixed', this.handleStreetRemixed)
    }

    loadStreets () {
        this.serverRequest = $.get(API_URL + 'v1/starting-streets', function (result) {
            var starting_streets = result.streets.map(unpackStreetDataFromServerTransmission);
            this.setState({
                starting_streets: starting_streets
            });
        }.bind(this));
    }

    componentDidMount() {
        if (getImagesToBeLoaded() === 0) {
            this.loadStreets()
        }
    }

    componentWillUnmount() {
        if (this.serverRequest) {
            this.serverRequest.abort();
        }
    }

    updateSignInUI (event) {
        // Sign-in details are passed in via `event.detail`. If a user is not
        // signed in, the event that calls this will pass an empty object for
        // `event.detail`
        if (event.detail.userId) {
            this.setState({ userId: event.detail.userId })
        }
    }

    handleRemixStreet (e) {
        e.preventDefault();

        const index = e.currentTarget.dataset['index'];
        const street = this.state.starting_streets[index];
        setStreet(street)

        remixStreet()
    }

    handleStreetRemixed () {
        propagateUnits()

        // TODO this is stupid, only here to fill some structures
        createDomFromData()
        createDataFromDom()

        let street = getStreet()
        setLastStreet(street)
        street.name = street.name.replace(' (remix)', '');
        setStreet(street)
        updateStreetName();
        let ignore = getIgnoreStreetChanges()
        setIgnoreStreetChanges(false)
        saveStreetToServerIfNecessary()
        setIgnoreStreetChanges(ignore)

        setServerContacted(true)
        checkIfEverythingIsLoaded()

        browserHistory.push(getStreetUrl(street))
    }

    render () {
        return (
            <Page className='home'>

                <h2>RESTREET allows you to recreate streets, share them and capture the feedback from others. </h2>
                <h3>You can check out the "starting streets" we have made below and submit your thoughts.</h3>
                <h3>
                    Or you can create your own account so you can make your own streets and send them to other people to see what they think of them.
                  <br/>
                    We look forward to seeing your thoughts and working together to rethink the future of our streets.
                </h3>

                <br/>
                <br/>

                <ul className="starting-streets">
                    {this.state.starting_streets.map((street, index) =>
                    <li key={street.id}>
                        <a href="#" onClick={this.handleRemixStreet} data-index={index}>
                            <StreetThumbnail
                                street={street}
                                silhouette={false}
                                bottomAligned={false}
                                transparentSky={true}
                                segmentNamesAndWidths={false}
                                streetName={false}
                            />
                            <StreetName
                                name={street.name}
                            />
                        </a>
                    </li>
                    )}
                </ul>
            </Page>
        )
    }
}
