'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var DOMWrap = _react2["default"].createClass({
  displayName: 'DOMWrap',

  propTypes: {
    tag: _react.PropTypes.string,
    hiddenClassName: _react.PropTypes.string,
    visible: _react.PropTypes.bool
  },

  getDefaultProps: function getDefaultProps() {
    return {
      tag: 'div'
    };
  },
  render: function render() {
    var props = (0, _extends3["default"])({}, this.props);
    if (!props.visible) {
      props.className = props.className || '';
      props.className += ' ' + props.hiddenClassName;
    }
    var Tag = props.tag;
    delete props.tag;
    delete props.hiddenClassName;
    delete props.visible;
    return _react2["default"].createElement(Tag, props);
  }
});

exports["default"] = DOMWrap;
module.exports = exports['default'];