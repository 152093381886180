/**
 * Streetmix
 *
 */

import Raven from 'raven-js'
import React from 'react'
import ReactDOM from 'react-dom'

// Polyfills
import 'babel-polyfill'
import 'whatwg-fetch' // fetch API
import 'handjs' // microsoft's pointer events / touch-action spec
import './vendor/canvas-toBlob.js'
import './vendor/Blob.js'
import './vendor/modernizr-custom'
import './vendor/polyfills/customevent' // customEvent in IE

// Main object
import { initialize } from './app/initialization'
import { startListening } from './app/keypress'
import { system } from './preinit/system_capabilities'
import { app } from './preinit/app_settings'
import MenusContainer from './menus/MenusContainer'
import Palette from './app/Palette'
import StreetNameCanvas from './streets/StreetNameCanvas'
import DebugInfo from './app/DebugInfo'

// Error tracking
// Load this before all other modules. Only load when run in production.
if (window.location.hostname === 'streetmix.net' || window.location.hostname === 'www.streetmix.net') {
  Raven.config('https://fac2c23600414d2fb78c128cdbdeaf6f@app.getsentry.com/82756', {
    whitelistUrls: [/streetmix\.net/, /www\.streetmix\.net/]
  }).install()
}

function setScaleForPhone () {
  var meta = document.createElement('meta')
  meta.setAttribute('name', 'viewport')

  if (system.phone) {
    meta.setAttribute('content', 'initial-scale=.5, maximum-scale=.5')
  } else {
    meta.setAttribute('content', 'initial-scale=1, maximum-scale=1')
  }

  var headEls = document.getElementsByTagName('head')
  headEls[0].appendChild(meta)
}
setScaleForPhone()

// Temp: mount React components
ReactDOM.render(<MenusContainer />, document.getElementById('menus'))
ReactDOM.render(<Palette />, document.getElementById('palette'))
ReactDOM.render(<StreetNameCanvas allowEditing={!app.readOnly} />, document.getElementById('street-header'))
ReactDOM.render(<DebugInfo />, document.getElementById('debug'))

// Start listening for keypresses
startListening()

import HomePage from './pages/HomePage'
import App from './containers/App'
import Admin from './containers/Admin'
import Street from './pages/Street'
import Dashboard from './pages/Dashboard'
import Results from './pages/Results'
import NoMatch from './pages/NoMatch'
import { Router, Route, IndexRoute, browserHistory } from 'react-router'

ReactDOM.render((
    <Router history={browserHistory}>
      <Route path="/" component={App}>
        <IndexRoute component={HomePage}/>
        <Route path="admin" component={Admin}>
          <Route path="results/:street_id" component={Results} />
          <Route path="dashboard" component={Dashboard} />
        </Route>
        {/*<Route path=":userId/:namespaceId(/:streetName)" component={Street}/>*/}
        <Route path="*" component={NoMatch}/>
      </Route>
    </Router>
), document.getElementById('pages-react'))


initialize()
