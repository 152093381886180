'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var Divider = _react2["default"].createClass({
  displayName: 'Divider',

  propTypes: {
    disabled: _react.PropTypes.bool,
    className: _react.PropTypes.string,
    rootPrefixCls: _react.PropTypes.string
  },

  getDefaultProps: function getDefaultProps() {
    return {
      disabled: true
    };
  },
  render: function render() {
    var _props = this.props,
        _props$className = _props.className,
        className = _props$className === undefined ? '' : _props$className,
        rootPrefixCls = _props.rootPrefixCls;

    return _react2["default"].createElement('li', { className: className + ' ' + rootPrefixCls + '-item-divider' });
  }
});

exports["default"] = Divider;
module.exports = exports['default'];