/**
 * About Streetmix (dialog box)
 *
 * Handles the "About" dialog box.
 * Instantiates an instance of Dialog
 * Exports nothing
 *
 */
import React from 'react'
import Dialog from './Dialog'
import Avatar from '../app/Avatar'
import { trackEvent } from '../app/event_tracking'
import { t } from '../app/locale'

export default class AboutDialog extends React.Component {
  componentDidMount () {
    trackEvent('Interaction', 'Open about dialog box', null, null, false)
  }

  render () {
    return (
      <Dialog className='about-dialog'>
        <h1>{t('dialogs.about.heading', 'About RESTREET')}</h1>
        <div className='about-dialog-left'>
          <div className='about-dialog-description'>
            RESTREET is a tool that builds on the Code for America project Streetmix, to provide more dynamic participatory planning of streets.
          </div>
          <ul>
            <li>
              <a href='https://github.com/codeforamerica/streetmix/' target='_blank'>
                {t('dialogs.about.view-source', 'View Streetmix on Github')}
              </a>
            </li>
          </ul>
        </div>
        <div className='about-dialog-right'>
          <p>
            The project involved two Cal Poly, San Luis Obispo professors and web developer:
          </p>
          <ul className="about-dialog-team">
            <li>
              William Riggs, project co-lead, researcher, designer. <a href="https://twitter.com/billyriggs" target="_blank">Twitter</a> <a href="mailto:contact@streetplan.org">Email</a>
            </li>
            <li>
              Michael Boswell, project co-lead, researcher, outreach <a href="https://twitter.com/mboswell" target="_blank">Twitter</a>
            </li>
            <li>
              Ryder Ross, web developer. <a href="https://twitter.com/ryduh" target="_blank">Twitter</a> <a href="mailto:ryder@rosswebconsulting.com">Email</a>
            </li>
          </ul>
          <footer>
          </footer>
        </div>
      </Dialog>
    )
  }
}
