'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _KeyCode = require('rc-util/lib/KeyCode');

var _KeyCode2 = _interopRequireDefault(_KeyCode);

var _addEventListener = require('rc-util/lib/Dom/addEventListener');

var _addEventListener2 = _interopRequireDefault(_addEventListener);

var _contains = require('rc-util/lib/Dom/contains');

var _contains2 = _interopRequireDefault(_contains);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

exports["default"] = {
  componentDidMount: function componentDidMount() {
    this.componentDidUpdate();
  },
  componentDidUpdate: function componentDidUpdate() {
    if (this.props.mode !== 'inline') {
      if (this.props.open) {
        this.bindRootCloseHandlers();
      } else {
        this.unbindRootCloseHandlers();
      }
    }
  },
  handleDocumentKeyUp: function handleDocumentKeyUp(e) {
    if (e.keyCode === _KeyCode2["default"].ESC) {
      this.props.onItemHover({
        key: this.props.eventKey,
        item: this,
        hover: false
      });
    }
  },
  handleDocumentClick: function handleDocumentClick(e) {
    // If the click originated from within this component
    // don't do anything.
    if ((0, _contains2["default"])(_reactDom2["default"].findDOMNode(this), e.target)) {
      return;
    }
    var props = this.props;
    props.onItemHover({
      hover: false,
      item: this,
      key: this.props.eventKey
    });
    this.triggerOpenChange(false);
  },
  bindRootCloseHandlers: function bindRootCloseHandlers() {
    if (!this._onDocumentClickListener) {
      this._onDocumentClickListener = (0, _addEventListener2["default"])(document, 'click', this.handleDocumentClick);
      this._onDocumentKeyupListener = (0, _addEventListener2["default"])(document, 'keyup', this.handleDocumentKeyUp);
    }
  },
  unbindRootCloseHandlers: function unbindRootCloseHandlers() {
    if (this._onDocumentClickListener) {
      this._onDocumentClickListener.remove();
      this._onDocumentClickListener = null;
    }

    if (this._onDocumentKeyupListener) {
      this._onDocumentKeyupListener.remove();
      this._onDocumentKeyupListener = null;
    }
  },
  componentWillUnmount: function componentWillUnmount() {
    this.unbindRootCloseHandlers();
  }
};
module.exports = exports['default'];