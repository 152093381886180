'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _MenuMixin = require('./MenuMixin');

var _MenuMixin2 = _interopRequireDefault(_MenuMixin);

var _util = require('./util');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var Menu = _react2["default"].createClass({
  displayName: 'Menu',

  propTypes: {
    openSubMenuOnMouseEnter: _react.PropTypes.bool,
    closeSubMenuOnMouseLeave: _react.PropTypes.bool,
    selectedKeys: _react.PropTypes.arrayOf(_react.PropTypes.string),
    defaultSelectedKeys: _react.PropTypes.arrayOf(_react.PropTypes.string),
    defaultOpenKeys: _react.PropTypes.arrayOf(_react.PropTypes.string),
    openKeys: _react.PropTypes.arrayOf(_react.PropTypes.string),
    mode: _react.PropTypes.string,
    onClick: _react.PropTypes.func,
    onSelect: _react.PropTypes.func,
    onDeselect: _react.PropTypes.func,
    onDestroy: _react.PropTypes.func,
    openTransitionName: _react.PropTypes.string,
    openAnimation: _react.PropTypes.oneOfType([_react.PropTypes.string, _react.PropTypes.object]),
    level: _react.PropTypes.number,
    eventKey: _react.PropTypes.string,
    selectable: _react.PropTypes.bool,
    children: _react.PropTypes.any
  },

  mixins: [_MenuMixin2["default"]],

  getDefaultProps: function getDefaultProps() {
    return {
      openSubMenuOnMouseEnter: true,
      closeSubMenuOnMouseLeave: true,
      selectable: true,
      onClick: _util.noop,
      onSelect: _util.noop,
      onOpenChange: _util.noop,
      onDeselect: _util.noop,
      defaultSelectedKeys: [],
      defaultOpenKeys: []
    };
  },
  getInitialState: function getInitialState() {
    var props = this.props;
    var selectedKeys = props.defaultSelectedKeys;
    var openKeys = props.defaultOpenKeys;
    if ('selectedKeys' in props) {
      selectedKeys = props.selectedKeys || [];
    }
    if ('openKeys' in props) {
      openKeys = props.openKeys || [];
    }
    return {
      selectedKeys: selectedKeys,
      openKeys: openKeys
    };
  },
  componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
    var props = {};
    if ('selectedKeys' in nextProps) {
      props.selectedKeys = nextProps.selectedKeys || [];
    }
    if ('openKeys' in nextProps) {
      props.openKeys = nextProps.openKeys || [];
    }
    this.setState(props);
  },
  onDestroy: function onDestroy(key) {
    var state = this.state;
    var props = this.props;
    var selectedKeys = state.selectedKeys;
    var openKeys = state.openKeys;
    var index = selectedKeys.indexOf(key);
    if (!('selectedKeys' in props) && index !== -1) {
      selectedKeys.splice(index, 1);
    }
    index = openKeys.indexOf(key);
    if (!('openKeys' in props) && index !== -1) {
      openKeys.splice(index, 1);
    }
  },
  onItemHover: function onItemHover(e) {
    var _this = this;

    var item = e.item;
    var _props = this.props,
        mode = _props.mode,
        closeSubMenuOnMouseLeave = _props.closeSubMenuOnMouseLeave;
    var _e$openChanges = e.openChanges,
        openChanges = _e$openChanges === undefined ? [] : _e$openChanges;
    // special for top sub menu

    if (mode !== 'inline' && !closeSubMenuOnMouseLeave && item.isSubMenu) {
      (function () {
        var activeKey = _this.state.activeKey;
        var activeItem = _this.getFlatInstanceArray().filter(function (c) {
          return c && c.props.eventKey === activeKey;
        })[0];
        if (activeItem && activeItem.props.open) {
          openChanges = openChanges.concat({
            key: item.props.eventKey,
            item: item,
            originalEvent: e,
            open: true
          });
        }
      })();
    }
    openChanges = openChanges.concat(this.getOpenChangesOnItemHover(e));
    if (openChanges.length) {
      this.onOpenChange(openChanges);
    }
  },
  onSelect: function onSelect(selectInfo) {
    var props = this.props;
    if (props.selectable) {
      // root menu
      var selectedKeys = this.state.selectedKeys;
      var selectedKey = selectInfo.key;
      if (props.multiple) {
        selectedKeys = selectedKeys.concat([selectedKey]);
      } else {
        selectedKeys = [selectedKey];
      }
      if (!('selectedKeys' in props)) {
        this.setState({
          selectedKeys: selectedKeys
        });
      }
      props.onSelect((0, _extends3["default"])({}, selectInfo, {
        selectedKeys: selectedKeys
      }));
    }
  },
  onClick: function onClick(e) {
    this.props.onClick(e);
  },
  onOpenChange: function onOpenChange(e_) {
    var props = this.props;
    var openKeys = this.state.openKeys.concat();
    var changed = false;
    var processSingle = function processSingle(e) {
      var oneChanged = false;
      if (e.open) {
        oneChanged = openKeys.indexOf(e.key) === -1;
        if (oneChanged) {
          openKeys.push(e.key);
        }
      } else {
        var index = openKeys.indexOf(e.key);
        oneChanged = index !== -1;
        if (oneChanged) {
          openKeys.splice(index, 1);
        }
      }
      changed = changed || oneChanged;
    };
    if (Array.isArray(e_)) {
      // batch change call
      e_.forEach(processSingle);
    } else {
      processSingle(e_);
    }
    if (changed) {
      if (!('openKeys' in this.props)) {
        this.setState({ openKeys: openKeys });
      }
      props.onOpenChange(openKeys);
    }
  },
  onDeselect: function onDeselect(selectInfo) {
    var props = this.props;
    if (props.selectable) {
      var selectedKeys = this.state.selectedKeys.concat();
      var selectedKey = selectInfo.key;
      var index = selectedKeys.indexOf(selectedKey);
      if (index !== -1) {
        selectedKeys.splice(index, 1);
      }
      if (!('selectedKeys' in props)) {
        this.setState({
          selectedKeys: selectedKeys
        });
      }
      props.onDeselect((0, _extends3["default"])({}, selectInfo, {
        selectedKeys: selectedKeys
      }));
    }
  },
  getOpenTransitionName: function getOpenTransitionName() {
    var props = this.props;
    var transitionName = props.openTransitionName;
    var animationName = props.openAnimation;
    if (!transitionName && typeof animationName === 'string') {
      transitionName = props.prefixCls + '-open-' + animationName;
    }
    return transitionName;
  },
  isInlineMode: function isInlineMode() {
    return this.props.mode === 'inline';
  },
  lastOpenSubMenu: function lastOpenSubMenu() {
    var lastOpen = [];
    var openKeys = this.state.openKeys;

    if (openKeys.length) {
      lastOpen = this.getFlatInstanceArray().filter(function (c) {
        return c && openKeys.indexOf(c.props.eventKey) !== -1;
      });
    }
    return lastOpen[0];
  },
  renderMenuItem: function renderMenuItem(c, i, subIndex) {
    if (!c) {
      return null;
    }
    var state = this.state;
    var extraProps = {
      openKeys: state.openKeys,
      selectedKeys: state.selectedKeys,
      openSubMenuOnMouseEnter: this.props.openSubMenuOnMouseEnter
    };
    return this.renderCommonMenuItem(c, i, subIndex, extraProps);
  },
  render: function render() {
    var props = (0, _extends3["default"])({}, this.props);
    props.className += ' ' + props.prefixCls + '-root';
    return this.renderRoot(props);
  }
});

exports["default"] = Menu;
module.exports = exports['default'];