import React from 'react'

import { onSignOutClick, getSignInData } from '../users/authentication'
import { URL_SIGN_IN_REDIRECT } from '../app/routing'
import Menu, { SubMenu, Item as MenuItem, Divider } from 'rc-menu';
import { browserHistory } from 'react-router'
// import 'rc-menu/assets/index.css';

export default class DashboardMenu extends React.Component {
  constructor (props) {
    super(props)

    const signin = getSignInData()

    this.state = {
      userId: signin ? signin.details.id : null,
    }
    this.updateSignInUI = this.updateSignInUI.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    window.addEventListener('stmx:signed_in', this.updateSignInUI)
  }

  updateSignInUI (event) {
    // Sign-in details are passed in via `event.detail`. If a user is not
    // signed in, the event that calls this will pass an empty object for
    // `event.detail`
    if (event.detail.userId) {
      this.setState({userId: event.detail.userId})
    }
  }

  handleSelect (info) {
    if (info.key === 'sign-out') {
      onSignOutClick()
      this.setState({userId: null})
    } else if (info.key === 'sign-in') {
      window.location = info.item.props.href;
    } else {
      browserHistory.push(info.item.props.href);
    }
  }

  render () {
    const userId = this.state.userId

    const userSignedInStyle = userId ? {} : {display: 'none'}
    const userSignedOutStyle = userId ? {display: 'none'} : {}

    return (
      <nav className='dashboard-menu-bar'>

        <Menu mode="horizontal" onSelect={this.handleSelect} /*onOpenChange={onOpenChange}*/>
          <MenuItem className="logo" key="2" href="/">
            <img src="/images/logo.png" alt="RESTREET"/>
          </MenuItem>
          <MenuItem key="3" style={userSignedInStyle} href="/admin/dashboard">
            Admin Dashboard
          </MenuItem>
          <MenuItem key="sign-out" style={userSignedInStyle}>
            Sign out
          </MenuItem>
          <MenuItem key="sign-in" style={userSignedOutStyle} href={`/${URL_SIGN_IN_REDIRECT}`}>
            Sign in
          </MenuItem>
        </Menu>
      </nav>
    )
  }
}

DashboardMenu.propTypes = {}
