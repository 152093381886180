import React from 'react'
import Page from './Page'
import $ from 'jquery'
import { API_URL } from '../app/config'
import StreetThumbnail from '../components/StreetThumbnail'
import StreetName from '../components/StreetName'
import { getSignInData } from '../users/authentication'
import { unpackStreetDataFromServerTransmission } from '../streets/xhr'
import { getAuthHeader } from '../users/authentication'
import moment from 'moment'

export default class Results extends React.Component {
    constructor (props) {
        super(props)

        const signin = getSignInData()

        this.state = {
            userId: signin ? signin.details.id : null,
            street: null,
            stats: null,
            submissions: []
        }
        this.loadStreet = this.loadStreet.bind(this)
        this.updateSignInUI = this.updateSignInUI.bind(this)
        window.addEventListener('stmx:signed_in', this.updateSignInUI)
    }

    updateSignInUI (event) {
        // Sign-in details are passed in via `event.detail`. If a user is not
        // signed in, the event that calls this will pass an empty object for
        // `event.detail`
        if (event.detail.userId) {
            this.setState({ userId: event.detail.userId })
        }

        this.loadStreet()
    }

    loadStats() {
      var options = {
        url: API_URL + 'v1/dashboard/stats/' + this.props.routeParams.street_id,
        dataType: 'json',
        headers: { 'Authorization': getAuthHeader() },
      }
      this.serverRequest = $.ajax(options).done((result) => {
        this.setState({
          stats: result,
        });
      });
    }

    loadStreet () {
      var self = this;
        var options = {
            url: API_URL + 'v1/dashboard/results/' + this.props.routeParams.street_id,
            dataType: 'json',
            headers: { 'Authorization': getAuthHeader() },
        }
        this.serverRequest = $.ajax(options).done((result) => {
            var street = unpackStreetDataFromServerTransmission(result.originalStreet)
            var submitted_streets = result.submissions.map(unpackStreetDataFromServerTransmission);
            this.setState({
                street: street,
                submissions: submitted_streets
            });
            self.loadStats();
        });
    }

    componentDidMount() {
        if (this.state.userId) {
            this.loadStreet()
        }
    }

    componentWillUnmount() {
        if (this.serverRequest) {
            this.serverRequest.abort();
        }
    }

    render () {
        return (
            <Page className='dashboard'>
                <h1>RESTREET Results for {this.state.street ? this.state.street.name : <small>Loading</small>} </h1>
                <h2>Starting Street</h2>
                {(() => { if (this.state.street) {
                        return (
                            <div className="street-thumbnail">
                                <StreetThumbnail
                                    street={this.state.street}
                                    silhouette={false}
                                    bottomAligned={false}
                                    transparentSky={true}
                                    segmentNamesAndWidths={false}
                                    streetName={false}
                                />
                                <StreetName name={this.state.street.name} />
                            </div>
                        )
                    }
                })()}

              {(() => { if (this.state.stats) {
                return (
                  <div className="stats">
                    <h2>Stats:</h2>
                    <table>
                      <tbody>

                      <tr>
                        <th>Total Submissions:</th>
                        <td>{this.state.stats.totalSubmissions.length}</td>
                      </tr>
                      <tr>
                        <th>Average Sidewalk Width:</th>
                        <td>{this.state.stats.avgSidewalkWidth}</td>
                      </tr>
                      <tr>
                        <th>Average Drive Lane Width:</th>
                        <td>{this.state.stats.avgDriveLaneWidth}</td>
                      </tr>

                      {this.state.stats.driveLanes.map(function(lane) { return (
                        <tr key={lane.lanes}>
                          <th>Streets with {lane.lanes} Drive Lanes:</th>
                          <td>{lane.submissions}</td>
                        </tr>
                      )})}

                      {this.state.stats.bikeLanes.map(function(lane) { return (
                          <tr key={lane.lanes}>
                          <th>Streets with {lane.lanes} Bike Lanes:</th>
                          <td>{lane.submissions}</td>
                        </tr>
                      )})}

                      {this.state.stats.parkingLanes.map(function(lane) { return (
                        <tr key={lane.lanes}>
                          <th>Streets with {lane.lanes} Parking Lanes:</th>
                          <td>{lane.submissions}</td>
                        </tr>
                      )})}
                      </tbody>
                    </table>
                  </div>
                )
              }
              })()}


                <h2>Submissions:</h2>

                <ul className="starting-streets">
                    {this.state.submissions.map((street, index) =>
                        <li key={street.id}>
                            <a href="#" data-index={index}>
                                <StreetThumbnail
                                    street={street}
                                    silhouette={false}
                                    bottomAligned={false}
                                    transparentSky={true}
                                    segmentNamesAndWidths={false}
                                    streetName={false}
                                />
                                <StreetName
                                    name={street.name}
                                />
                            </a>
                            <table>
                                <tbody>

                                <tr>
                                    <th>Submitted</th>
                                    <td>
                                        {moment(street.submission.submittedOn).format('MM/DD/YY')}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Age</th>
                                    <td>{street.submission.age}</td>
                                </tr>
                                <tr>
                                    <th>Zip</th>
                                    <td>{street.submission.zip}</td>
                                </tr>
                                </tbody>
                            </table>
                        </li>
                    )}
                </ul>
            </Page>
        )
    }
}

Results.propTypes = {
    street_id: React.PropTypes.string,
}
